// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_row__wbkQh {
    padding-bottom: 3 rem;
}

.Home_buttonIconDisabled__AwlDe {
    cursor: not-allowed;
    opacity: 0.5;
    margin-right: 0.5rem;
  }

.Home_fullButton__ATOPQ {
    width: 100%
}
  
.Home_customButton__LLL0j {
    --bs-btn-color: #000000 !important;
    --bs-btn-bg: #FFCC00 !important;
    --bs-btn-border-color: #FFCC00 !important;
    --bs-btn-hover-color: #000000 !important;
    --bs-btn-hover-bg: #dcb000 !important;
    --bs-btn-hover-border-color: #dcb000 !important;
    --bs-btn-focus-shadow-rgb: 38, 106, 221 !important;
    --bs-btn-active-color: #000000 !important;
    --bs-btn-active-bg: #dcb000 !important;
    --bs-btn-active-border-color: #deb50f !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: #000000 !important;
    --bs-btn-disabled-bg: #FFCC00 !important;
    --bs-btn-disabled-border-color: #FFCC00 !important;
}

:root {
--success-color: #4ebfc7;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,oBAAoB;EACtB;;AAEF;IACI;AACJ;;AAEA;IACI,kCAAkC;IAClC,+BAA+B;IAC/B,yCAAyC;IACzC,wCAAwC;IACxC,qCAAqC;IACrC,+CAA+C;IAC/C,kDAAkD;IAClD,yCAAyC;IACzC,sCAAsC;IACtC,gDAAgD;IAChD,uEAAuE;IACvE,2CAA2C;IAC3C,wCAAwC;IACxC,kDAAkD;AACtD;;AAEA;AACA,wBAAwB;AACxB","sourcesContent":[".row {\r\n    padding-bottom: 3 rem;\r\n}\r\n\r\n.buttonIconDisabled {\r\n    cursor: not-allowed;\r\n    opacity: 0.5;\r\n    margin-right: 0.5rem;\r\n  }\r\n\r\n.fullButton {\r\n    width: 100%\r\n}\r\n  \r\n.customButton {\r\n    --bs-btn-color: #000000 !important;\r\n    --bs-btn-bg: #FFCC00 !important;\r\n    --bs-btn-border-color: #FFCC00 !important;\r\n    --bs-btn-hover-color: #000000 !important;\r\n    --bs-btn-hover-bg: #dcb000 !important;\r\n    --bs-btn-hover-border-color: #dcb000 !important;\r\n    --bs-btn-focus-shadow-rgb: 38, 106, 221 !important;\r\n    --bs-btn-active-color: #000000 !important;\r\n    --bs-btn-active-bg: #dcb000 !important;\r\n    --bs-btn-active-border-color: #deb50f !important;\r\n    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;\r\n    --bs-btn-disabled-color: #000000 !important;\r\n    --bs-btn-disabled-bg: #FFCC00 !important;\r\n    --bs-btn-disabled-border-color: #FFCC00 !important;\r\n}\r\n\r\n:root {\r\n--success-color: #4ebfc7;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `Home_row__wbkQh`,
	"buttonIconDisabled": `Home_buttonIconDisabled__AwlDe`,
	"fullButton": `Home_fullButton__ATOPQ`,
	"customButton": `Home_customButton__LLL0j`
};
export default ___CSS_LOADER_EXPORT___;
